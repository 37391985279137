import React from 'react';
import PropTypes from 'prop-types';
import './video-section.css';
import LoadingSpinner from './loading-spinner';

const VideoSection = ({ src, title, videoId }) => (
  <div>
    <div className="video-iframe-container">
      <div
        title={title}
        style={{
          width: '100%',
          height: '100%',
          position: 'absolute',
          left: 0,
          top: 0,
          paddingTop: '30%',
          zIndex: '-1',
        }}
        frameBorder="0"
        width="100%"
        height="100%"
      >
        <LoadingSpinner />
      </div>
      <iframe
        title={title}
        style={{
          width: '100%',
          height: '100%',
          position: 'absolute',
          left: 0,
          top: 0,
        }}
        frameBorder="0"
        width="100%"
        height="100%"
        allowFullScreen
        allow="autoplay"
        src={src || `https://www.youtube.com/embed/${videoId}?rel=0&modestbranding=1`}
      />
    </div>
  </div>
);

VideoSection.propTypes = {
  src: PropTypes.string,
  title: PropTypes.string,
  videoId: PropTypes.string,
};

VideoSection.defaultProps = {
  src: null,
  title: 'Code Championship Pump Up Video',
  videoId: 'p_i76rgLKXU',
};

export default VideoSection;
