import React from 'react';

const QuoteStripeLily = () => (
  <div className="stripe-hr">
    <q>
      This was so much fun!!!!!!!!!
    </q>
    <br />
    <br />
    - Lily, Coder, 6th Grade
  </div>
);

export default QuoteStripeLily;
