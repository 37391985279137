import React from 'react';
import SEO from '../components/seo';
import Layout from '../components/layout';
import NavigationButton from '../components/navigation-button';
import QuoteStripeLily from '../components/quote-stripe-lily';
import VideoSection from '../components/video-section';

import './index.css';

const IndexPage = () => (
  <Layout style={{ height: '100%' }}>
    <SEO
      title="Code Championship | Code is Sport"
      description="Code is Sport. Competitive coding competitions for elementary, middle, and high school students."
      keywords={['competition', 'united states', 'national']}
    />
    {/* <QuoteStripeCodeIsSport /> */}
    <center style={{ marginBottom: '30px' }}>
      <div>
        <VideoSection
          title="What is Code Championship?"
          src="https://www.youtube.com/embed/ks80WhgW294?rel=0&modestbranding=1"
        />
      </div>
    </center>
    <div className="two-wide-container">
      <div>
        <div className="pricing-panel">
          <div className="panel-title">
            Coders
          </div>
          <div className="panel-body">
            <p>
              Join our fun, competitive, and friendly virtual
              coding tournaments for grades 3 to 9.
            </p>
            <NavigationButton
              path="/coders/"
              text="Learn More"
            />
          </div>
        </div>
      </div>
      <div className="pricing-panel">
        <div className="panel-title">
          Educators
        </div>
        <div className="panel-body">
          <p>Learn how to run Code Championship Tournaments for your students.</p>
          <NavigationButton
            path="/educators/"
            text="Learn More"
          />
        </div>
      </div>
    </div>
    <QuoteStripeLily />
    <h1>Code Championship</h1>
    <div className="stripe-hr thin" />
    <h2>Code is Sport</h2>
    <p>
      Code is Sport! Just like gymnastics
      {' '}
      <span aria-hidden="true">🤸‍♀️</span>
      , dance
      {' '}
      <span aria-hidden="true">💃</span>
      , or basketball
      {' '}
      <span aria-hidden="true">🏀</span>
      . Competitive coding is fun and pushes us to be great!
      Join a tournament
      {' '}
      <span aria-hidden="true">🏆</span>
      {' '}
      and play head-to-head against other coders!
      {' '}
      <span aria-hidden="true">👩‍💻</span>
    </p>
    <div className="two-wide-container">
      <div style={{ margin: '3px' }}>
        <VideoSection
          title="How is coding like dance?"
          videoId="yT5WTilYH5s"
        />
      </div>
      <div style={{ margin: '3px' }}>
        <VideoSection
          title="How is coding like basketball?"
          videoId="01zImPTar-o"
        />
      </div>
      <div style={{ margin: '3px' }}>
        <VideoSection
          title="How is coding like running?"
          videoId="HkZmpSxeOw8"
        />
      </div>
      <div style={{ margin: '3px' }}>
        <VideoSection
          title="How is coding like karate?"
          videoId="zhNPJ4dUkJo"
        />
      </div>
    </div>
  </Layout>
);

export default IndexPage;
